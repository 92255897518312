import {types} from "mobx-state-tree";

export const UpdateStore = types
    .model("UpdateStore", {
        available: types.optional(types.boolean, false)
    })
    .actions((self) => ({
        setAvailable: (value: boolean) => {
            self.available = value;
        }
    }));

export const Update = UpdateStore.create({});
