import {ReactElement} from "react";

export class Alertify {
    public static alert(title = "Meldung", message?: string | ReactElement, onOk?: () => void) {
        const func = async () => {
            const opener = await import("../react/Standard/Alertify/opener/openAlert");
            return opener.openAlert(title, message, onOk);
        };
        func().catch(console.error);
    }

    public static confirm(
        title = "Meldung",
        message?: string,
        onOk?: () => void,
        onCancel?: () => void,
        settings?: {labels: {ok?: string; cancel?: string}}
    ) {
        const func = async () => {
            const opener = await import("../react/Standard/Alertify/opener/openConfirm");
            return opener.openConfirm(title, message, onOk, onCancel, settings);
        };
        return func().catch(console.error);
    }

    public static prompt(
        title = "Meldung",
        message?: string,
        value?: string,
        onOk?: (event: undefined, value: string) => void,
        onCancel?: () => void,
        settings?: {labels?: {ok?: string; cancel?: string}; height?: number | "auto"; inputLabel?: string}
    ) {
        const func = async () => {
            const opener = await import("../react/Standard/Alertify/opener/openPrompt");
            return opener.openPromptString(title, message, value, onOk, onCancel, settings);
        };
        return func().catch(console.error);
    }

    public static promptNumeric(
        title = "Meldung",
        message?: string,
        value?: number,
        onOk?: (event: undefined, value: number) => void,
        onCancel?: () => void,
        settings?: {labels: {ok?: string; cancel?: string}}
    ) {
        const func = async () => {
            const opener = await import("../react/Standard/Alertify/opener/openPrompt");
            return opener.openPromptNumeric(title, message, value, onOk, onCancel, settings);
        };
        return func().catch(console.error);
    }
}
