import {ApiCaller} from "../../lib/ApiCaller";
import {minuteMilliseconds} from "../../lib/DateQuirks";
import {Service} from "./Service";

export class ApiService extends Service {
    protected apiCaller: ApiCaller;

    protected constructor() {
        super();
        this.apiCaller = new ApiCaller();
    }
}

interface ISearchResult<cacheData> {
    stamp: Date;
    erg: cacheData;
    cacheTimeout?: number;
}

export class ApiServiceSearchCache<cacheData> {
    /**
     * das maximale Cachealter in Minuten
     */
    public maxCacheAge: number;

    /**
     * ob das maximale Cachealter berücksichtigt werden soll
     */
    public ignoreMaxAge: boolean;

    protected searchHistory: Map<string, ISearchResult<cacheData>> = new Map();

    /**
     *
     * @param {number} maxCacheAge das maximale Cachealter in Minuten
     * @param {boolean} ignoreMaxAge ob das maximale Cachealter berücksichtigt werden soll
     */
    constructor(maxCacheAge: number = 5, ignoreMaxAge: boolean = false) {
        this.searchHistory = new Map();
        this.maxCacheAge = maxCacheAge;
        this.ignoreMaxAge = ignoreMaxAge;
    }

    public add(searchString: string, data: cacheData) {
        this.clearSearchResult(searchString);
        this.searchHistory.set(searchString, {
            erg: data,
            stamp: new Date(),
            cacheTimeout: window.setTimeout(() => {
                const searchResult = this.searchHistory.get(searchString);
                if (searchResult && !this.checkNotExpired(searchResult)) {
                    this.searchHistory.delete(searchString);
                }
            }, this.maxCacheAge * minuteMilliseconds)
        });
    }

    public clear() {
        this.searchHistory.clear();
    }

    public delete(searchString: string) {
        this.clearSearchResult(searchString);
        this.searchHistory.delete(searchString);
    }

    public get(searchString: string): cacheData | undefined {
        const searchResult = this.searchHistory.get(searchString);
        let returnValue;
        if (searchResult) {
            if (this.checkNotExpired(searchResult)) {
                returnValue = searchResult.erg;
            }
        }
        return returnValue;
    }

    public clearAllExpired() {
        const expired: string[] = [];
        this.searchHistory.forEach((value, key) => {
            if (!this.checkNotExpired(value)) {
                expired.push(key);
            }
        });
        expired.forEach((value) => {
            this.delete(value);
        });
    }

    protected clearSearchResult(searchString: string) {
        const searchResult = this.searchHistory.get(searchString);
        if (searchResult) {
            clearTimeout(searchResult.cacheTimeout);
        }
    }

    protected checkNotExpired(searchResult: ISearchResult<cacheData>) {
        let cacheAgeCheck = true;
        if (!this.ignoreMaxAge) {
            cacheAgeCheck =
                (new Date().getTime() - searchResult.stamp.getTime()) / minuteMilliseconds < this.maxCacheAge;
        }
        return cacheAgeCheck;
    }
}
