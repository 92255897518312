export interface SendObject {
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    [key: string]: SendObject | unknown;
}

export class FormDataCreator<sendObject extends SendObject = SendObject> {
    private readonly objectToSend: sendObject;
    private readonly objectShadow: SendObject;
    private readonly objectSpec: {[key: string]: Blob};
    constructor(objectToSend: sendObject) {
        this.objectToSend = objectToSend;
        this.objectShadow = {};
        this.objectSpec = {};
    }

    public preparedObjectData() {
        this.builder(this.objectToSend, this.objectShadow, "", ([keyName, item], _, shadowedObject) => {
            shadowedObject[keyName] = item;
        });
    }

    private getShadowStringNext(shadowObjectString: string | undefined, keyName: string): string {
        let shadowObjectStringNext;

        if (shadowObjectString && shadowObjectString.length > 0) {
            shadowObjectStringNext = shadowObjectString + ("[" + keyName + "]");
        } else {
            shadowObjectStringNext = keyName;
        }

        return shadowObjectStringNext;
    }

    private rawStringReduction(reduceFunction: (accu: FormData, val: string[]) => FormData, initialValue: FormData) {
        const rawString = this.param().replaceAll("+", " ");
        return rawString.split("&").reduce(function (accu, current) {
            const keyVal = current.split("=");
            return reduceFunction(accu, keyVal);
        }, initialValue);
    }

    private addObjectSpecs(addFunction: (keyName: string, value: Blob) => void) {
        Object.entries(this.objectSpec).forEach(([keyName, value]) => {
            addFunction(keyName, value);
        });
    }

    public getFormData() {
        return this.appendExisting(new FormData());
    }

    /**
     *
     * @param {FormData|URLSearchParams} browserDataObject
     */
    private appendExisting(browserDataObject: FormData) {
        const formData = this.rawStringReduction(function (accu, [key, val]) {
            if (key) {
                accu.append(decodeURIComponent(key), decodeURIComponent(val || ""));
            }
            return accu;
        }, browserDataObject);
        this.addObjectSpecs((key: string, value: Blob) => {
            formData.append(key, value);
        });
        return formData;
    }

    private isSpecialObject(item: unknown): item is Blob {
        return (
            Object.prototype.toString.call(item) === "[object File]" ||
            Object.prototype.toString.call(item) === "[object Blob]"
        );
    }

    private isSendObject(item: unknown): item is sendObject | sendObject[] {
        return Array.isArray(item) || Object.prototype.toString.call(item) === "[object Object]";
    }
    private isBaseType(item: unknown): item is string | number | boolean | null | undefined {
        return (
            typeof item === "string" ||
            typeof item === "number" ||
            typeof item === "boolean" ||
            item === undefined ||
            item === null
        );
    }

    public param(): string {
        const collector: string[] = [];
        this.builder(this.objectToSend, this.objectShadow, "", ([_, value], prop: string) => {
            if (value === undefined || value === null) {
                return;
            }
            collector.push(
                `${encodeURIComponent(prop)}=${encodeURIComponent(
                    this.isBaseType(value) ? value : Object.prototype.toString.call(value)
                )}`
            );
        });
        return collector.join("&");
    }

    private builder(
        objectData: sendObject | sendObject[],
        shadowedObject: SendObject,
        shadowObjectString: string,
        leafAction: (entry: [string, unknown], shadowObjectStringNext: string, shadowedObject: SendObject) => void
    ) {
        Object.entries(objectData).forEach(([keyName, item]) => {
            const shadowObjectStringNext = this.getShadowStringNext(shadowObjectString, keyName);

            if (this.isSpecialObject(item)) {
                this.objectSpec[shadowObjectStringNext] = item;
            } else if (this.isSendObject(item)) {
                const tmp: SendObject = {};
                shadowedObject[keyName] = tmp;
                this.builder(item, tmp, shadowObjectStringNext, leafAction);
            } else {
                leafAction([keyName, item], shadowObjectStringNext, shadowedObject);
            }
        });
    }
}
