import {Update} from "../GlobalStores/Update";
import {alertify} from "../legacy/helper_alertify";
import {translate} from "../GlobalStores/Language";

declare const VERSION: string;
const langGet = await translate("versionAlert");

function alert() {
    if (!Update.available) {
        Update.setAvailable(true);
        alertify.warning(langGet("EsIstEinUp"));
    }
}

export function versionAlert(version: string) {
    if (VERSION !== version) {
        alert();
    }
}
