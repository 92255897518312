import {
    CSSProperties,
    memo,
    MouseEvent,
    MouseEventHandler,
    ReactNode,
    TouchEventHandler,
    useCallback,
    useContext
} from "react";
import {InlineSvg, ValidIconName, ValidIconSize} from "../Svg/InlineSvg";
import {WorkingStateContext} from "../Tab/Lib/WorkingStateContext";
import {isTouchBased} from "../../GlobalStores/ProfileFromStorage";
import * as styles from "./StandardButton.module.less";
import {useElevationClass} from "./Paper";

const translate = {
    primary: styles.buttonPrimary,
    secondary: styles.buttonSecondary,
    tertiary: styles.buttonTertiary,
    sidebar: styles.buttonSidebar,
    error: styles.buttonError,
    warning: styles.buttonWarning,
    success: styles.buttonSuccess,
    question: styles.buttonQuestion,
    info: styles.buttonInfo,
    default: styles.buttonDefault,
    transparent: styles.buttonTransparent
};

export type ColorSet = keyof typeof translate;

interface IProps {
    tabIndex?: number | undefined;
    iconName?: ValidIconName;
    iconSize?: "S" | "M" | "L" | "XL";
    iconRotate?: number;
    className?: string;
    color?: ColorSet;
    active?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    title?: string;
    circle?: boolean;
    onClick?: ((e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => Promise<unknown>) | undefined;
    onMouseOver?: MouseEventHandler | undefined;
    onMouseEnter?: MouseEventHandler | undefined;
    onMouseOut?: MouseEventHandler | undefined;
    onMouseDown?: MouseEventHandler | undefined;
    onTouchStart?: TouchEventHandler | undefined;
    children?: ReactNode;
    useDiv?: boolean;
    flat?: boolean;
    slim?: boolean;
}

export const StandardButton = memo(function StandardButton(props: IProps) {
    const ref = useContext(WorkingStateContext);
    const debounced = useCallback(
        (e: MouseEvent<HTMLDivElement | HTMLButtonElement>): void => {
            if (props.onClick) {
                if (ref.current) {
                    const [working, setWorking] = ref.current;
                    if (props.onClick && !working) {
                        setWorking(true);
                        props.onClick(e)?.finally(() => {
                            setWorking(false);
                        });
                    }
                } else {
                    props.onClick(e).catch(console.error);
                }
            }
        },
        [props, ref]
    );

    const utilClass = [translate[props.color || "secondary"]];

    if (props.className) {
        utilClass.push(props.className);
    }

    if (!isTouchBased() || props.color === "transparent") {
        utilClass.push(styles.desktop);
    }

    const elevationClassFlat = useElevationClass(0, true);
    const elevationClassFloating = useElevationClass(2, true);

    if (props.flat || props.color === "transparent") {
        // color = transparent = Workaround für den Moment, bis wir die Buttons überall umgestellt haben
        utilClass.push(elevationClassFlat);
    } else {
        utilClass.push(elevationClassFloating);
    }

    if (props.children) {
        utilClass.push(styles.buttonWithText);
    }

    if (props.circle) {
        utilClass.push(styles.circle);
    }

    if (props.active) {
        utilClass.push(styles.active);
    }

    if (props.slim || props.color === "transparent") {
        // color = transparent = Workaround für den Moment, bis wir die Buttons überall umgestellt haben
        utilClass.push(styles.slim);
    }

    const translateIconSize: {S: ValidIconSize; M: ValidIconSize; L: ValidIconSize; XL: ValidIconSize} = {
        S: 8,
        M: 16,
        L: 24,
        XL: 32
    };

    const content = (
        <>
            {props.iconName ? (
                <InlineSvg
                    rotate={props.iconRotate}
                    name={props.iconName}
                    size={translateIconSize[props.iconSize || "M"]}
                />
            ) : undefined}
            {props.children ? <div>{props.children}</div> : undefined}
        </>
    );

    const wrapperStyle = {
        tabIndex: props.tabIndex,
        style: props.style,
        disabled: props.disabled,
        className: utilClass.join(" "),
        onClick: debounced,
        onMouseDown: props.onMouseDown,
        onTouchStart: props.onTouchStart,
        onMouseEnter: props.onMouseEnter,
        onMouseOver: props.onMouseOver,
        onMouseOut: props.onMouseOut,
        title: props.title
    };

    let wrapper;
    if (props.useDiv) {
        if (wrapperStyle.disabled) {
            wrapperStyle.className += " " + styles.disabled;
        }

        delete wrapperStyle.disabled;

        wrapper = <div {...wrapperStyle}>{content}</div>;
    } else {
        wrapper = (
            <button type={"button"} {...wrapperStyle}>
                {content}
            </button>
        );
    }

    return wrapper;
});
