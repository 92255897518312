import {PHPW} from "../../generated";
import {ApiService} from "./ApiService";

export class SetupService extends ApiService {
    public getDeviceType = async (abortSignal?: AbortSignal) => {
        const result = await this.apiCaller.call<PHPW.Api.Routes.Lib.Setup.Model.DeviceType>({
            route: ["setup", "deviceType"],
            method: "GET",
            abortSignal: abortSignal
        });

        return typeof result.response === "string" ? undefined : result.response;
    };

    public getPaymentById = async (args: PHPW.Api.Lib.Common.ArgumentsId, abortSignal?: AbortSignal) => {
        const result = await this.apiCaller.call<PHPW.Api.Routes.Lib.Setup.Model.Payment>({
            route: ["setup", "payment", args.id],
            method: "GET",
            abortSignal: abortSignal
        });

        return typeof result.response === "string" ? undefined : result.response;
    };

    public postPayment = async (payload: PHPW.Api.Routes.Lib.Setup.Model.Payment, abortSignal?: AbortSignal) => {
        const result = await this.apiCaller.call<PHPW.Api.Responses.SaveResponse>({
            route: ["setup", "payment"],
            method: "POST",
            abortSignal: abortSignal,
            body: payload
        });

        return typeof result.response === "string" ? undefined : result.response;
    };
}
