// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/no-redundant-type-constituents
function reviver(_key: string, value: any): undefined | any {
    if (value === null) {
        return undefined;
    }
    return value;
}

export function jsonParse(json: string): unknown {
    return JSON.parse(json, reviver);
}
