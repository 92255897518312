import {SSE} from "sse.js";
import {
    IInternalCallOptions,
    ILegacyCallOptions,
    IRequestHandlerBody,
    IRequestHandlerParams,
    IRequestHandlerResponse,
    RequestHandler,
    TRequestMethod
} from "../RequestHandler";
import {getProjectOrigin} from "../functions/getProjectOrigin";

export class ApiCaller extends RequestHandler {
    private url = "/api/public/v1/index.php";

    get path() {
        return getProjectOrigin(this.url);
    }

    public createApiRoute(route?: string | Array<string | number | undefined>, params?: IRequestHandlerParams) {
        return `${this.path}/${Array.isArray(route) ? route.join("/") : route || ""}${ApiCaller.formatParams(params)}`;
    }

    public triggerEventStream(
        route: string | Array<string | number>,
        method: Exclude<TRequestMethod, "HEAD"> = "GET",
        params?: IRequestHandlerParams,
        body?: IRequestHandlerBody
    ): SSE {
        return new SSE(this.createApiRoute(route, params), {
            method: method,
            payload: JSON.stringify(body) || "",
            headers: {"Content-Type": "application/json"}
        });
    }

    /**
     * @throws {IRequestHandlerResponseError}
     * @throws {TypeError} von fetch
     * @throws {DOMException} von fetch
     */
    public override async legacyCall<T>(
        route: string | Array<string | number>,
        method: Exclude<TRequestMethod, "HEAD"> = "GET",
        params?: IRequestHandlerParams,
        body?: IRequestHandlerBody,
        abortSignal?: AbortSignal,
        options?: ILegacyCallOptions
    ): Promise<IRequestHandlerResponse<T>> {
        return super.call<T>({route: this.createApiRoute(route), method, params, body, abortSignal, ...options});
    }

    /**
     * @throws {IRequestHandlerResponseError}
     * @throws {TypeError} von fetch
     * @throws {DOMException} von fetch
     */
    public override async call<T>(options: IInternalCallOptions): Promise<IRequestHandlerResponse<T>> {
        options.route = this.createApiRoute(options.route);
        return super.call<T>(options);
    }
}
