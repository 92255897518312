import {useCallback, useState} from "react";
import {IExceptionFragment} from "../../../../Service/IErrorResponse";
import {StandardButton} from "../../../Standard/StandardButton";
import {translate} from "../../../../GlobalStores/Language";
import {icon_visibility, icon_visibility_off} from "../../../../lib/svg/MaterialIcons2.ts";

const langGet = await translate("ErrorResponse");

export interface IErrorResponseProps {
    title: string;
    message: string;
    route?: string;
    detail?: IExceptionFragment;
    chain?: IErrorResponseProps[];
    onlyNotify?: "error" | "warning";
    overrideNotifyAction?: () => void;
}

function ErrorEntry(props: IErrorResponseProps) {
    const [showDetail, setShowDetail] = useState(false);
    const onClick = useCallback(async () => {
        setShowDetail(!showDetail);
    }, [showDetail]);
    return (
        <div style={{border: "black solid 1px"}}>
            <StandardButton
                style={{width: "100%", userSelect: "text"}}
                color={"transparent"}
                iconName={showDetail ? icon_visibility : icon_visibility_off}
                title={langGet("öffnen")}
                onClick={onClick}
            >
                {props.message}
            </StandardButton>
            {showDetail ? (
                <div>
                    <div>Titel: {props.title}</div>
                    {props.route ? <div>Pfad: {props.route}</div> : undefined}
                    {props.detail ? (
                        <>
                            <div>Fehler: {props.detail.exception}</div>
                            <div>Meldung: {props.detail.exceptionMessage}</div>
                            <div>Fehlercode: {props.detail.code}</div>
                            <div>
                                Datei: {props.detail.file}:{props.detail.line}
                            </div>
                            <div>{props.detail.trace?.join("\n")}</div>
                        </>
                    ) : undefined}
                </div>
            ) : undefined}
        </div>
    );
}

export function ErrorResponse(props: IErrorResponseProps) {
    const [showChain, setShowChain] = useState(false);
    const onClick = useCallback(async () => {
        setShowChain(!showChain);
    }, [showChain]);
    return (
        <>
            <ErrorEntry {...props} />
            {props.chain && props.chain.length > 0 ? (
                <>
                    <StandardButton
                        iconName={showChain ? icon_visibility : icon_visibility_off}
                        title={langGet("öffnen")}
                        onClick={onClick}
                    >
                        Mehr
                    </StandardButton>
                    {showChain ? (
                        <>
                            {props.chain.map(function (entry, index) {
                                // eslint-disable-next-line react/no-array-index-key
                                return <ErrorEntry key={index} {...entry} />;
                            })}
                        </>
                    ) : undefined}
                </>
            ) : undefined}
        </>
    );
}
