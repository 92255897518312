import {DetailedHTMLProps, HTMLAttributes, RefAttributes} from "react";
import * as styles from "./Paper.module.less";

export type ValidElevation =
    | 0
    | 1
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8
    | 9
    | 10
    | 11
    | 12
    | 13
    | 14
    | 15
    | 16
    | 17
    | 18
    | 19
    | 20
    | 21
    | 22
    | 23
    | 24;

interface IProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    elevation?: ValidElevation;
    square?: boolean;
}

export function useElevationClass(absoluteElevation: ValidElevation = 1, withHover = false): string {
    function isValidElevation(elevation: number): elevation is ValidElevation {
        if (elevation > 24) {
            return false;
        } else if (elevation < 0) {
            return false;
        }

        return Number.isInteger(elevation);
    }

    const relative = absoluteElevation;

    const relativeElevation = isValidElevation(relative) ? relative : 0;
    const intermediate = relativeElevation + 1;
    const relHoverElevation = isValidElevation(intermediate) ? intermediate : 1;

    if (withHover) {
        return styles[`elevation_${relativeElevation}`] + " " + styles[`elevation_withHover_${relHoverElevation}`];
    }
    return styles[`elevation_${relativeElevation}`];
}

export const Paper = function Paper(props: IProps & RefAttributes<HTMLDivElement | null>) {
    const elevationClass = useElevationClass(props.elevation);

    const classNames: string[] = [elevationClass];

    if (props.className) {
        classNames.push(props.className);
    }

    const propsCopy: Partial<IProps> = {
        ...props
    };

    delete propsCopy.square;
    delete propsCopy.className;

    return <div {...propsCopy} ref={props.ref} className={classNames.join(" ")} />;
};
