import {SetupService} from "../react/Service/SetupService.ts";

export function getProfileFromStorage(): string {
    return localStorage.getItem("profile") || "root";
}

export function setProfileToStorage(value: string | undefined) {
    if (value === undefined) {
        return localStorage.removeItem("profile");
    }

    localStorage.setItem("profile", value);
}

export function isTouchBased(): boolean {
    const profile = getProfileFromStorage();
    return profile === "smartphone" || profile === "tablet";
}

export function isSmartphone(): boolean {
    const profile = getProfileFromStorage();
    return profile === "smartphone";
}

export function isTablet(): boolean {
    const profile = getProfileFromStorage();
    return profile === "tablet";
}

export async function initProfile() {
    const local = localStorage.getItem("profile");
    if (local) {
        setProfileToStorage(local);
    } else {
        const deviceType = await SetupService.init().getDeviceType();

        if (deviceType) {
            if (deviceType.isPhone) {
                setProfileToStorage("smartphone");
            }
            if (deviceType.isTablet) {
                setProfileToStorage("tablet");
            }
        }
    }
}
