import {createElement, CSSProperties, FunctionComponent, memo, SVGProps} from "react";
import * as styles from "./InlineSvg.module.less";

export type ValidIconName = FunctionComponent<SVGProps<SVGSVGElement>>;

export type ValidIconSize = 8 | 16 | 18 | 20 | 24 | 28 | 32 | 34 | 40 | 48 | 64 | 150;

interface IProps {
    name?: ValidIconName;
    className?: string;
    rotate?: number;
    size?: ValidIconSize;
    title?: string;
    id?: string;
    onClick?(): void;
    onDoubleClick?(): void;
}

interface ContainerProps {
    style?: CSSProperties;
    className?: string;
    size?: ValidIconSize;
    title?: string;
}

interface SVGContainerProps extends ContainerProps {
    svgString: string;
    onClick?(this: void): void;
    onDoubleClick?(this: void): void;
    id?: string;
}

export const SvgContainer = memo(function SvgContainer(props: SVGContainerProps) {
    let className = styles.container;
    let style: CSSProperties = {height: props.size, width: props.size};

    if (props.className) {
        className = props.className + " " + className;
    }

    if (props.style) {
        style = {height: props.size, width: props.size, ...props.style};
    }

    return (
        <div
            className={className}
            style={style}
            title={props.title}
            id={props.id}
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            dangerouslySetInnerHTML={{__html: props.svgString}}
        />
    );
});

export function InlineSvg(props: IProps) {
    let className = styles.container;
    const style: CSSProperties = {height: props.size, width: props.size};

    if (props.className) {
        className = props.className + " " + className;
    }

    if (props.rotate) {
        style.transform = `rotate(${props.rotate}deg)`;
    }

    return (
        <div className={className} style={style} title={props.title} id={props.id}>
            {props.name ? createElement(props.name) : undefined}
        </div>
    );
}
