/**
 * Hiermit lässt sich ein typof reverten auch bei abstrakten typen oder Klassen mit Privatem constructor
 * ansonsten ist {@link InstanceType} vorzuziehen
 * @see {@link https://stackoverflow.com/a/54281690} quelle für die idee
 */
type AbstractInstanceType<T> = T extends {prototype: infer U} ? U : never;

export class Service {
    /**
     * gibt die Service instanz zurück
     */
    public static init<T extends typeof Service>(this: T): AbstractInstanceType<T> {
        return (this.instance || (this.instance = new this())) as AbstractInstanceType<T>;
    }

    protected static instance: Service;

    protected constructor() {
        /* ist ein singleton */
    }
}
