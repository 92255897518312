import {Lock} from "./GlobalStores/Lock";
import {FormDataCreator, SendObject} from "./lib/FormDataCreator";
import {versionAlert} from "./lib/versionAlert";
import {IErrorResponse} from "./Service/IErrorResponse";
import {handleErrorResponse} from "./Service/handleErrorResponse";
import {jsonParse} from "./functions/jsonParse";

type IResponseBody = unknown;

interface IRequestHandlerResponseBase<T = IResponseBody> {
    status: number;
    mimeString: string;
    filename?: string;
    response?: T;

    raw: boolean;
    rawResponse?: Blob | File;

    text: boolean;

    error?: IErrorResponse;
}

export interface IRequestHandlerResponseError extends IRequestHandlerResponseBase<undefined> {
    error: IErrorResponse;
}

interface IRequestHandlerResponseTyped<T = IResponseBody, isText extends boolean = false, isRaw extends boolean = false>
    extends IRequestHandlerResponseBase<T> {
    raw: isRaw;

    text: isText;

    error?: undefined;
}

interface IRequestHandlerResponseRaw extends IRequestHandlerResponseTyped<undefined, false, true> {
    rawResponse: Blob | File;
}

interface IRequestHandlerResponseText extends IRequestHandlerResponseTyped<string, true> {
    response: string;
}

type IRequestHandlerResponseInternal = IRequestHandlerResponseTyped<undefined>;

export type IRequestHandlerResponse<T = IResponseBody> =
    | IRequestHandlerResponseTyped<T>
    | IRequestHandlerResponseText
    | IRequestHandlerResponseRaw;

type IRequestHandlerInternal<T = IResponseBody> = IRequestHandlerResponse<T> | IRequestHandlerResponseError;

export type IRequestHandlerParams = unknown;

export type IRequestHandlerBody = unknown;

type TRequestMethodCorsSafe = "GET" | "HEAD" | "POST";
type TRequestMethodCustom = "ACTION";
export type TRequestMethod = TRequestMethodCorsSafe | "PATCH" | "PUT" | "DELETE" | TRequestMethodCustom;

interface IRequestData {
    route: string;
    method: TRequestMethod;

    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    params?: IRequestHandlerParams | undefined;

    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    body?: IRequestHandlerBody | undefined;
    abortSignal?: AbortSignal | undefined;
}

export interface ILegacyCallOptions {
    rawResponse?: boolean;
    disableRetryCache?: boolean;
    lock?: boolean;
}

export interface IInternalCallOptions extends ICallOptions {
    route: string | Array<string | number | undefined>;
    method: TRequestMethod;
}

export interface ICallOptions {
    params?: IRequestHandlerParams;
    body?: IRequestHandlerBody;
    abortSignal?: AbortSignal;
    rawResponse?: boolean;
    disableRetryCache?: boolean;
    lock?: boolean;
}

export class RequestHandler {
    private static retryCache = new Map<IRequestData, () => void>();

    public static formatParams(params: IRequestHandlerParams) {
        if (params) {
            let param;
            if (params instanceof FormData) {
                param = new URLSearchParams(params as unknown as Record<string, string>).toString();
            } else {
                param = new FormDataCreator(params as SendObject).param();
            }
            return "?" + param;
        }
        return "";
    }

    public static reCallCached() {
        RequestHandler.retryCache.forEach(function (value) {
            value();
        });
        RequestHandler.retryCache.clear();
    }

    private static prepareRequest(requestData: IRequestData) {
        const header = new Headers();
        header.append("X-Requested-With", "RequestHandler");
        if (["GET", "HEAD"].includes(requestData.method)) {
            return new Request(requestData.route + RequestHandler.formatParams(requestData.params), {
                headers: header,
                method: requestData.method,
                credentials: "same-origin",
                cache: "no-cache",
                signal: requestData.abortSignal
            });
        } else {
            if (requestData.body instanceof Blob) {
                // header.append("Content-Type", requestData.body.type);
            } else if (requestData.body instanceof FormData) {
                // header.append("Content-Type", "multipart/form-data");
            } else {
                header.append("Content-Type", "application/json");
            }

            return new Request(requestData.route + RequestHandler.formatParams(requestData.params), {
                body:
                    requestData.body instanceof FormData || requestData.body instanceof Blob
                        ? requestData.body
                        : JSON.stringify(requestData.body, function (_k, v) {
                              // eslint-disable-next-line unicorn/no-null,@typescript-eslint/no-unsafe-return
                              return v === undefined ? null : v;
                          }),
                headers: header,
                method: requestData.method,
                credentials: "same-origin",
                cache: "no-cache",
                signal: requestData.abortSignal
            });
        }
    }

    private static initialResponse(): IRequestHandlerResponseInternal {
        return {
            status: 500,
            mimeString: "",
            text: false,
            raw: false
        };
    }

    /**
     @throws {IRequestHandlerResponseError}
     @throws {TypeError} von fetch
     @throws {DOMException} von fetch
     */
    private static async handleRequest<T>(
        requestData: IRequestData,
        disableRetryCache?: boolean,
        rawResponse?: boolean,
        lock: boolean = true
    ): Promise<IRequestHandlerResponse<T>> {
        const request = RequestHandler.prepareRequest(requestData);
        const response = await fetch(request);
        return RequestHandler.handleResponse<T>(response.clone(), requestData, disableRetryCache, rawResponse, lock);
    }

    /**
     * @throws {IRequestHandlerResponseError}
     */
    private static async handleResponse<T>(
        response: Response,
        requestData: IRequestData,
        disableRetryCache?: boolean,
        rawResponse?: boolean,
        lock: boolean = true
    ): Promise<IRequestHandlerResponse<T>> {
        const ttl = Number(response.headers.get("x-cookie-ttl"));
        if (ttl > 0) {
            Lock.setTtl(ttl);
        }
        const version = response.headers.get("x-version");
        if (version) {
            versionAlert(version);
        }

        if (response.status === 401) {
            if (lock) {
                Lock.setLocked(true);
            }
            if (!disableRetryCache) {
                return new Promise(function (resolve, reject) {
                    RequestHandler.retryCache.set(requestData, function () {
                        RequestHandler.handleRequest<T>(requestData, disableRetryCache, rawResponse)
                            .then(resolve)
                            .catch(reject);
                    });
                });
            }
        } else {
            if (RequestHandler.retryCache.has(requestData)) {
                RequestHandler.retryCache.delete(requestData);
            }
        }

        return this.convertResponse<T>(response, requestData, rawResponse);
    }

    /**
     * @throws {IRequestHandlerResponseError}
     */
    private static async convertResponse<T>(
        response: Response,
        requestData: IRequestData,
        rawResponse?: boolean
    ): Promise<IRequestHandlerResponse<T>> {
        const apiResponseBase: IRequestHandlerResponseInternal = {
            ...RequestHandler.initialResponse(),
            status: response.status,
            mimeString: response.headers.get("Content-Type") || "",
            filename: (response.headers.get("Content-Disposition")?.match(/filename="(.+)"$/i) || [])[1]
        };
        const isError = this.isStatusError(apiResponseBase.status);
        if (isError) {
            rawResponse = undefined;
        }

        let apiResponse: IRequestHandlerResponse<T>;
        if (!rawResponse && apiResponseBase.mimeString.startsWith("application/json")) {
            apiResponse = {
                ...apiResponseBase,
                response: jsonParse(await response.text()) as T,
                raw: false,
                text: false
            };
        } else if (!rawResponse && apiResponseBase.mimeString.startsWith("text/")) {
            apiResponse = {
                ...apiResponseBase,
                response: await response.text(),
                text: true,
                raw: false
            };
        } else {
            let blobResponse = await response.blob();
            if (apiResponseBase.filename) {
                blobResponse = new File([blobResponse], apiResponseBase.filename, {type: apiResponseBase.mimeString});
            }
            apiResponse = {
                ...apiResponseBase,
                rawResponse: blobResponse,
                // response: blobResponse,
                raw: true,
                text: false
            };
        }
        if (isError) {
            this.apiResponseToError(apiResponse, requestData);
        }
        return apiResponse;
    }

    private static isStatusError(status: number) {
        // status auf die Coderange reduzieren und mit den relevanten Ranges vergleichen
        return [4, 5, 9].includes(Math.trunc(status / 100));
    }

    /**
     * @throws {IRequestHandlerResponseError}
     */
    private static apiResponseToError<T>(apiResponse: IRequestHandlerInternal<T>, requestData: IRequestData): never {
        if (apiResponse.raw) {
            apiResponse.error = {message: "Unknown structure"};
        } else {
            if (apiResponse.text) {
                apiResponse.error = {message: apiResponse.response || ""};
                apiResponse.text = false;
                apiResponse.response = undefined;
            } else {
                apiResponse.error = apiResponse.response as unknown as IErrorResponse;
                apiResponse.response = undefined;
            }
        }
        apiResponse.error.route = requestData.route;

        throw apiResponse;
    }

    public async legacyCall<T>(
        route: string,
        method: TRequestMethod = "GET",
        params?: IRequestHandlerParams,
        body?: IRequestHandlerBody,
        abortSignal?: AbortSignal,
        options?: ILegacyCallOptions
    ): Promise<IRequestHandlerResponse<T>> {
        return this.call({
            ...options,
            route: route,
            method: method,
            params: params,
            body: body,
            abortSignal: abortSignal
        });
    }

    /**
     * @throws {IRequestHandlerResponseError}
     * @throws {TypeError} von fetch
     * @throws {DOMException} von fetch
     */
    public async call<T>(options: IInternalCallOptions): Promise<IRequestHandlerResponse<T>> {
        const callOptions: IInternalCallOptions = {
            lock: options.lock === undefined ? true : options.lock,
            ...options
        };

        try {
            import("./functions/Heart")
                .then(function (imported) {
                    return imported.resetTimer();
                })
                .catch(console.error);

            let route: string;
            if (Array.isArray(callOptions.route)) {
                route = callOptions.route.filter((part) => part !== undefined).join("/");
            } else {
                route = callOptions.route;
            }

            return await RequestHandler.handleRequest<T>(
                {
                    route: route,
                    method: callOptions.method,
                    params: callOptions.params,
                    body: callOptions.body,
                    abortSignal: callOptions.abortSignal
                },
                callOptions.disableRetryCache,
                callOptions.rawResponse,
                callOptions.lock
            ).catch((exception: IRequestHandlerInternal<T> | TypeError | DOMException) => {
                // defaulthandling, nur wenn überhaupt ein error da ist
                if (exception && typeof exception === "object" && "error" in exception && exception.error) {
                    const skipDefaultErrorHandling = [404];
                    // standardverhalten für disableRetryCache setzen
                    if (callOptions.disableRetryCache) {
                        skipDefaultErrorHandling.push(401);
                    }
                    // prüfen ob der status code enthalten ist
                    if (!skipDefaultErrorHandling.includes(exception.status)) {
                        handleErrorResponse(exception.error);
                    }
                }

                throw exception;
            });
        } catch (e) {
            if (e && typeof e === "object" && "status" in e && e?.status === 401) {
                // 401 braucht keinen log
            } // eslint-disable-next-line @typescript-eslint/no-deprecated
            else if (e && e instanceof DOMException && (e.name === "AbortError" || e.code === e.ABORT_ERR)) {
                // abortSignal braucht keinen log
            } else {
                console.error(e);
            }

            throw e;
        }
    }
}
