/**
 * Gibt den wahren basispfad zurück oder hängt ihn vor den übergebenen string;
 *
 * @returns string projectOrigin
 */

export class Location {
    public static getPathname() {
        return window.location.pathname;
    }

    public static getOrigin() {
        return window.location.origin;
    }
}

export function getProjectOrigin(somepath = "") {
    const path = Location.getPathname() + somepath;
    let pathElements = path.split("/");
    const lastIsAlwaysRelevant = pathElements.pop() || "";
    pathElements = pathElements.reduce<string[]>((acc, cur) => {
        if (cur.length > 0) {
            acc.push(cur);
        }
        return acc;
    }, []);
    pathElements.push(lastIsAlwaysRelevant);
    return "/" + pathElements.join("/");
}
